import { Link, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FilmTemplate = ({ data }) => {
  const { date, title, image } = data.markdownRemark.frontmatter
  const { html } = data.markdownRemark
  const publishedDate = new Date(date)

  return (
    <Layout>
      <GatsbySeo title="Film Venue Page" />
      <GridContent
        layout="--1-column content-margin-top content-margin-bottom"
        mode="white-mode"
      >
        <h4>Film Productions</h4>
        <h1>{title}</h1>
        <GatsbyImage image={getImage(image)} alt={title} />
        <h2>
          Published {publishedDate.getDate()}.{publishedDate.getMonth()}.
          {publishedDate.getFullYear()}
        </h2>

        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div
          style={{
            display: 'flex',
            marginTop: 'auto',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
          }}
        >
          <Link to="/film-location" className="mock-button">
            <h4>Return to Film Locations page</h4>
          </Link>
        </div>
      </GridContent>
    </Layout>
  )
}

FilmTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FilmTemplate

export const pageQuery = graphql`
  query FilmByID($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 820
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`
